/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-03-21",
    versionChannel: "nightly",
    buildDate: "2025-03-21T00:06:50.370Z",
    commitHash: "4b6536eac16ac02648523de749f6aed35588d028",
};
